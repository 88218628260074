<template>
  <div class="main has-bottom">
    <van-nav-bar class="top-bar" title="绑定手机" left-arrow @click-left="onClickLeft"/>
    <div class="mainPane">
      <div class="phone-box">
        <van-icon :name="require('@/assets/images/personal/label-phone.png')" />
        <div class="phone-text">已绑定手机号码：{{ phone }}</div>
        <div class="close-text">
          <van-tag color="#4E5969" plain @click="handleRemoveMobile">解绑</van-tag>
        </div>
      </div>
    </div>
    <div class="link-bottom">
      <van-button to="/personal/bind-phone">换绑手机号</van-button>
    </div>
  </div>
</template>

<script>
import {NavBar, Icon, Button, Tag, Dialog} from "vant";
import {useStore} from "vuex";
import {computed} from "vue";
import "@/style/common.css"
import {useRouter} from "vue-router";

export default {
  name: "BoundPhone",
  components:{
    [NavBar.name]:NavBar,
    [Icon.name]:Icon,
    [Button.name]:Button,
    [Tag.name]: Tag,
  },
  setup(){
    const store = useStore()
    const phone = computed(()=>{
      return store.state.userInfo.phone
    })
    //返回操作
    const onClickLeft = () => history.back();
    const router = useRouter()
    const handleRemoveMobile = () => {
      Dialog.confirm({
        message: '确定要解绑手机号吗?',
      }) .then(() => {
        router.push('/personal/unbinding-phone')
      })
    }
    return{
      phone,
      onClickLeft,
      handleRemoveMobile
    }
  }
}
</script>

<style scoped>
.phone-box{
  padding:30px 20px;
}
.phone-box .van-icon{
  font-size:66px;
}
.phone-text{
  font-size: 18px;
  font-weight: 500;
  line-height:20px;
  margin:10px 0px;
}
.close-text{
  font-size:12px;
  margin-top:10px;
}
.link-bottom{
  position: fixed;
  bottom:30px;
  left:0px;
  right:0px;
}
.link-bottom .van-button{
  width: 170px;
  height: 44px;
  background: #EBF1FF;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  color: #306AFF;
  box-sizing: border-box;
}

</style>
